import { FC } from 'react'
import styled from 'styled-components'
import { v4 } from 'uuid'

import { Modal } from 'Components/Modal/Modal'
import { PayInvoiceForm } from 'Pages/Employer/BillingPage/PayInvoice/PayInvoiceForm'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { spacingLG, spacingMD } from 'Utils/styles/spacing'
import { Invoice } from 'Utils/types/invoice'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${spacingLG};
  padding-top: ${spacingMD};
`

type PayInvoiceModalProps = {
  open: boolean
  onClose: () => void
  invoice: Invoice
}
export const PayInvoiceModal: FC<PayInvoiceModalProps> = ({
  open,
  onClose,
  invoice
}: PayInvoiceModalProps) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const idempotencyKey = v4()

  return (
    <Modal showModal={open} setShowModal={onClose} minWidth={500} maxWidth={500}>
      <Container>
        <PayInvoiceForm
          invoice={invoice}
          employerId={employerId}
          setShowModal={onClose}
          idempotencyKey={idempotencyKey}
        />
      </Container>
    </Modal>
  )
}
