import { FC } from 'react'
import TableCell from '@mui/material/TableCell'
import styled from 'styled-components'
import { GearIcon, DownloadIcon, ReaderIcon } from '@radix-ui/react-icons'

import {
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger
} from 'Components/DropdownMenu/DropdownMenu'

import { spacingXXS } from 'Utils/styles/spacing'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { useDownloadInvoice } from 'Hooks/api/employer/invoices/useDownloadInvoice'
import { Invoice } from 'Utils/types/invoice'

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${spacingXXS};
`

type InvoiceActionsTableCellProps = {
  invoice: Invoice
  setSelected: () => void
  toggleModal: () => void
}
export const InvoiceActionsTableCell: FC<InvoiceActionsTableCellProps> = ({
  invoice,
  setSelected,
  toggleModal
}) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()
  const { isLoading, mutate } = useDownloadInvoice()

  const handleDownloadInvoice = () => {
    if (employerId) {
      mutate({ employerId: employerId, invoice })
    }
  }

  const handleSelectOpenInvoiceDetails = () => {
    setSelected()
    toggleModal()
  }

  return (
    <TableCell>
      <Container>
        <DropdownRoot>
          <DropdownTrigger>
            <GearIcon />
          </DropdownTrigger>

          <DropdownContent>
            <DropdownItem onSelect={handleSelectOpenInvoiceDetails}>
              <ReaderIcon />
              View details
            </DropdownItem>
            <DropdownItem disabled={isLoading} onSelect={handleDownloadInvoice}>
              <DownloadIcon />
              Download
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </Container>
    </TableCell>
  )
}
