import styled from 'styled-components'
import React, { useCallback, useMemo } from 'react'
import { uniq } from 'ramda'

import { StyledSelect } from 'Components/Inputs/StyledSelect'
import { ClearFiltersButton } from 'Components/Table/ClearFiltersButton'
import { InvoiceTableFilters } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceTable'
import { InvoiceStateLabels } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceStatus'
import { useCountries } from 'Hooks/useCountries'

import { InvoiceState, InvoiceTableRow } from 'Utils/types/invoice'
import { FlexColumn, GridColumn, spacingMD, spacingSM } from 'Utils/styles/spacing'
import { TableLabel } from 'Utils/styles/text'
import { CountryCode } from 'Utils/types/countries'

const ActionsContainer = styled.div<{ countryFilter: boolean }>`
  display: grid;
  grid-column-gap: ${spacingMD};
  align-items: center;
  grid-template-columns: 25% 35% ${(countryFilter) => (countryFilter ? '25%' : '')};
  padding-bottom: ${spacingMD};
`

type PeopleTableFiltersProps = {
  invoices: InvoiceTableRow[]
  filters: InvoiceTableFilters
  updateFilters: (newFilters: Partial<InvoiceTableFilters>) => void
  clearFilters?: () => void
  disabled: boolean
}

export const InvoiceTableActions = ({
  invoices,
  updateFilters,
  clearFilters,
  disabled
}: PeopleTableFiltersProps) => {
  const handleStateChange = useCallback(
    (state: InvoiceState | '') => updateFilters({ state }),
    [updateFilters]
  )
  const handlePaymentMethodChange = useCallback(
    (paymentMethod: string) => updateFilters({ paymentMethod }),
    [updateFilters]
  )
  const handleCountryChange = useCallback(
    (countryCode: CountryCode | '') => updateFilters({ countryCode }),
    [updateFilters]
  )

  const { hasMultipleCountries, countryOptionsWithAll } = useCountries()

  const stateOptions = useMemo<{ value: InvoiceState | ''; label: string }[]>(
    () => [
      { value: '', label: 'All statuses' },
      ...Object.values(InvoiceState).map((state) => ({
        value: state,
        label: InvoiceStateLabels[state]
      }))
    ],
    []
  )
  const paymentMethodOptions = useMemo(
    () => [
      { value: '', label: 'All payment methods' },
      ...uniq(
        invoices.map(({ paymentMethod }) => ({
          value: paymentMethod,
          label: paymentMethod
        }))
      )
    ],
    [invoices]
  )

  return (
    <FlexColumn gap={spacingSM}>
      <GridColumn>
        <TableLabel>Invoices</TableLabel>
        <ClearFiltersButton onClear={clearFilters} />
      </GridColumn>
      <ActionsContainer countryFilter={hasMultipleCountries}>
        <StyledSelect
          handleChange={handleStateChange}
          options={stateOptions}
          defaultValue={stateOptions[0]?.value}
          disabled={disabled}
        />
        <StyledSelect
          handleChange={handlePaymentMethodChange}
          options={paymentMethodOptions}
          disabled={disabled}
          defaultValue={paymentMethodOptions[0]?.value}
        />
        {hasMultipleCountries && (
          <StyledSelect
            handleChange={handleCountryChange}
            options={countryOptionsWithAll}
            disabled={disabled}
            defaultValue={countryOptionsWithAll[0]?.value}
          />
        )}
      </ActionsContainer>
    </FlexColumn>
  )
}
