import { FC, useState, MouseEvent } from 'react'
import { endOfToday } from 'date-fns'

import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { ScheduleRemovalMessage } from 'Components/Modal/ScheduleRemovalMessage'
import { ModalBody } from '../ModalBody'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { usePeoplePageContext } from 'Contexts/PeoplePageContext'

import { useRemoveUserQuery } from 'Hooks/api/employer/userManagement/useRemoveUserQuery'

import { ButtonContainer, spacingSM } from 'Utils/styles/spacing'
import { formatDateForServer, lastOfMonth } from 'Utils/helpers/dateUtils'
import { UserDetailsForEmployer } from 'Utils/types/user'

type RemoveUserFormProps = {
  user?: UserDetailsForEmployer
}
export const RemoveUserForm: FC<RemoveUserFormProps> = ({ user }) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const { setModal } = usePeoplePageContext()

  const removeUserQuery = useRemoveUserQuery()

  const [removeUserEndTime, setRemoveUserEndTime] = useState(endOfToday())

  const removeUser = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (user && employerId) {
      removeUserQuery.mutate({
        userId: user.id,
        employerId,
        endTime: formatDateForServer(removeUserEndTime)
      })
    }
  }

  const handleCloseModal = () => setModal(undefined)

  const minDate =
    user?.latestInvoiceDetails?.dateCreated && lastOfMonth(user.latestInvoiceDetails.dateCreated)

  const removeButtonText = removeUserEndTime <= endOfToday() ? 'status.removeNow' : 'status.remove'

  return (
    <>
      <ModalBody>
        <FormDatePicker
          minDate={minDate}
          date={removeUserEndTime}
          onChange={setRemoveUserEndTime}
          disabled={false}
        />

        <ScheduleRemovalMessage firstName={user?.userInfo.firstName} permanentRecommendation />
      </ModalBody>

      <ButtonContainer gap={spacingSM}>
        <StyledButton id="cancel.label" onClick={handleCloseModal} />
        <StyledButton primary id={removeButtonText} onClick={removeUser} />
      </ButtonContainer>
    </>
  )
}
