import { getRequest } from 'Services/baseRequests/getRequest'
import {
  GetInvoicesApiResponse,
  GetInvoicesResponse,
  RequestResponse
} from 'Services/apiResponseTypes'
import { parseInvoiceApiResponseData } from 'Services/invoices/parsers'
import { postRequest, postRequestDownloadFile } from 'Services/baseRequests/postRequest'

import { EmployerInfo } from 'Utils/types/employerInfo'
import { Invoice } from 'Utils/types/invoice'
import { PaymentMethod } from 'Utils/types/paymentMethod'

export type GetInvoicePayload = {
  perPage: number
  pageNumber: number
  employerId: EmployerInfo['id']
}

export const getInvoices = async ({
  employerId,
  pageNumber,
  perPage
}: GetInvoicePayload): Promise<GetInvoicesResponse> => {
  const response = await getRequest<GetInvoicesApiResponse>(
    `/employers/${employerId}/invoices/grouped?&page=${pageNumber}&per_page=${perPage}`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: {
      invoices: response?.data?.invoices.map(parseInvoiceApiResponseData) ?? [],
      totalInvoices: response?.data?.total_records ?? 0
    }
  }
}

export const downloadInvoice = async (employerId: EmployerInfo['id'], invoiceId: Invoice['id']) => {
  const response = await postRequestDownloadFile<ArrayBuffer>(
    `/employers/${employerId}/invoices/${invoiceId}/generate_csv`,
    undefined,
    { responseType: 'arraybuffer' }
  )

  return response
    ? {
        success: true,
        statusCode: response.statusCode ?? undefined,
        message: '',
        data: response.data
      }
    : {
        success: false,
        message: '',
        data: undefined
      }
}

export type SubmitInvoicePaymentPayload = {
  employerId: EmployerInfo['id']
  invoiceId: Invoice['id']
  paymentMethodId: PaymentMethod['id']
  idempotencyKey: string
}
export const submitInvoicePayment = async ({
  employerId,
  invoiceId,
  paymentMethodId,
  idempotencyKey
}: SubmitInvoicePaymentPayload): Promise<RequestResponse> => {
  const response = await postRequest<
    RequestResponse,
    { payment_method_id: PaymentMethod['id']; idempotency_key: string }
  >(`/employers/${employerId}/invoices/${invoiceId}/submit_payment`, {
    payment_method_id: paymentMethodId,
    idempotency_key: idempotencyKey
  })

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}
