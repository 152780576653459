import { FC } from 'react'
import styled from 'styled-components'

import { Modal } from 'Components/Modal/Modal'
import { InvoiceReimbursementsTable } from './InvoiceReimbursementsTable'
import { InvoiceChargeDetails } from 'Pages/Employer/BillingPage/InvoiceChargeDetails'
import { InvoiceStatus } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceStatus'

import { fontSizeLG, fontWeightBold } from 'Utils/styles/text'
import { displayMonthAndYear } from 'Utils/helpers/dateUtils'
import { FlexRow, spacingSM } from 'Utils/styles/spacing'
import { Invoice } from 'Utils/types/invoice'
import { getCurrency } from 'Utils/types/currencies'

const InvoiceDate = styled.div`
  margin: 0;
  font-size: ${fontSizeLG};
  font-weight: ${fontWeightBold};
`

type InvoiceDetailsModalProps = {
  showModal: boolean
  setShowModal: (a: boolean) => void
  invoice: Invoice
}
export const InvoiceDetailsModal: FC<InvoiceDetailsModalProps> = ({
  showModal,
  setShowModal,
  invoice
}) => {
  const { userReimbursements, countryCode, state } = invoice
  const reimbursementCurrency = getCurrency(countryCode)

  return (
    <Modal showModal={showModal} setShowModal={setShowModal} minWidth={800} overflow>
      <FlexRow gap={spacingSM}>
        <InvoiceDate>{displayMonthAndYear(invoice.dateCreated)}</InvoiceDate>
        <InvoiceStatus invoiceState={invoice.state} />
      </FlexRow>
      <InvoiceReimbursementsTable
        invoiceState={state}
        reimbursements={userReimbursements}
        currencyCode={reimbursementCurrency}
        hideStatus={invoice.alternativeReimbursement}
      />
      <InvoiceChargeDetails invoice={invoice} />
    </Modal>
  )
}
