import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { Root } from '@radix-ui/react-separator'

import { CurrencyText, ExchangeRateText } from 'Components/Text/CurrencyText'

import { PurpleText, TableLabel, ThinDarkGreenText } from 'Utils/styles/text'
import { FlexColumn, noPadding, spacingSM, spacingXS } from 'Utils/styles/spacing'
import { zirconGray } from 'Utils/styles/colors'
import { Invoice, InvoiceState } from 'Utils/types/invoice'
import { PaymentMethod, isCardPayment } from 'Utils/types/paymentMethod'
import { CountryCode } from 'Utils/types/countries'
import { getCurrency, CurrencyCode } from 'Utils/types/currencies'
import { roundAmount } from 'Utils/helpers/currency'

const ScrollableContainer = styled(FlexColumn)`
  overflow-y: auto;
  gap: ${spacingSM};
  max-height: 300px;
`

const InvoiceSummaryEntry = styled(ThinDarkGreenText)`
  display: grid;
  grid-template-columns: minmax(auto, 400px) auto;
`

const Separator = styled(Root)`
  height: 1px;
  width: 100%;
  margin: ${spacingXS} 0;
  background-color: ${zirconGray};
`

type InvoiceChargeDetailsProps = {
  invoice: Invoice
  paymentMethod?: PaymentMethod
}
export const InvoiceChargeDetails: FC<InvoiceChargeDetailsProps> = ({ invoice, paymentMethod }) => {
  const {
    invoiceBenefitPrograms,
    processingFee,
    numberOfUsersChargedFor,
    joonFees,
    exchangeRate,
    calculatedProcessingFee,
    creditCardSurcharge,
    benefitTransfersTotal,
    benefitTransferFees,
    totalAmount,
    countryCode,
    currencyCode,
    alternativeReimbursement,
    state
  } = invoice

  const benefitsCurrency = getCurrency(countryCode)

  const chargeInfo = useMemo(() => {
    const surchargeText = 'Credit card surcharge:'

    if (state === InvoiceState.Completed)
      return {
        surchargeText,
        surcharge: creditCardSurcharge ?? 0,
        total: totalAmount
      }

    const reimbursements = alternativeReimbursement ? 0 : benefitTransfersTotal
    const baseTotal = reimbursements + joonFees + benefitTransferFees

    if (!alternativeReimbursement && paymentMethod && isCardPayment(paymentMethod)) {
      const isInternational = paymentMethod.countryCode !== CountryCode.US
      const fee = isInternational ? 0.045 : 0.03
      const surcharge = roundAmount(baseTotal * fee)

      return {
        surchargeText: `Credit card surcharge (${isInternational ? '4.5' : '3'}%):`,
        surcharge,
        total: baseTotal + surcharge
      }
    }

    return { surchargeText, surcharge: 0, total: baseTotal }
  }, [
    benefitTransfersTotal,
    benefitTransferFees,
    creditCardSurcharge,
    paymentMethod,
    joonFees,
    state,
    totalAmount,
    alternativeReimbursement
  ])

  return (
    <>
      <TableLabel marginBottom={noPadding}>Charge details</TableLabel>
      <FlexColumn>
        <ScrollableContainer>
          {invoiceBenefitPrograms.map((program) => (
            <InvoiceSummaryEntry key={program.name}>
              {program.name} reimbursements:
              <span>
                <CurrencyText
                  value={Number(program.reimbursedAmount)}
                  currencyCode={benefitsCurrency}
                />
              </span>
            </InvoiceSummaryEntry>
          ))}
          <br />
          {!alternativeReimbursement && currencyCode != CurrencyCode.USDollar && (
            <>
              <InvoiceSummaryEntry>
                <span>Country Processing Fee:</span>
                <span>
                  <CurrencyText value={processingFee} currencyCode={CurrencyCode.USDollar} />
                </span>
              </InvoiceSummaryEntry>
              <InvoiceSummaryEntry>
                <span>Exchange Rate:</span>
                <span>
                  <ExchangeRateText value={exchangeRate} currencyCode={currencyCode} />
                </span>
              </InvoiceSummaryEntry>
              <InvoiceSummaryEntry>
                <span>Converted Fee:</span>
                <span>
                  <CurrencyText value={calculatedProcessingFee} currencyCode={currencyCode} />
                </span>
              </InvoiceSummaryEntry>
            </>
          )}
          <InvoiceSummaryEntry>
            <span>
              Joon Fees ({numberOfUsersChargedFor} x{' '}
              <CurrencyText value={calculatedProcessingFee} currencyCode={currencyCode} />
              ):
            </span>

            <span>
              <CurrencyText value={joonFees} currencyCode={currencyCode} />
            </span>
          </InvoiceSummaryEntry>
          {benefitTransferFees > 0 && (
            <InvoiceSummaryEntry>
              Reimbursement Fees (3.5%):
              <CurrencyText value={benefitTransferFees} currencyCode={currencyCode} />
            </InvoiceSummaryEntry>
          )}
          <InvoiceSummaryEntry>
            {chargeInfo.surchargeText}
            <span>
              <CurrencyText value={chargeInfo.surcharge} currencyCode={currencyCode} />
            </span>
          </InvoiceSummaryEntry>
        </ScrollableContainer>
        <FlexColumn>
          <Separator />
          <InvoiceSummaryEntry>
            Total:
            <PurpleText>
              <CurrencyText value={chargeInfo.total} currencyCode={currencyCode} />
            </PurpleText>
          </InvoiceSummaryEntry>
        </FlexColumn>
      </FlexColumn>
    </>
  )
}
