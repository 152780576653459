export const initializeBeamer = (isLoggedIn: boolean, userId?: number, employerId?: number) => {
  const s = document.createElement('script')

  s.type = 'text/javascript'
  s.async = true
  s.innerHTML = "var beamer_config = {product_id: 'zOwqcoWQ51651', selector: 'beamerSelector'"

  if (isLoggedIn && employerId !== undefined) {
    s.innerHTML += `, user_id: ${userId}`
  }

  s.innerHTML += '}'

  document.body.appendChild(s)
}
