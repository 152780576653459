import { Payment, PendingPaymentInfo } from 'Utils/types/payment'
import { CountryCode } from './countries'
import { CurrencyCode } from './currencies'

// TODO: Would be really nice to get a standard for these values in the DB ... diff casing all around
export enum InvoiceState {
  Draft = 'draft',
  Open = 'open',
  Processing = 'processing',
  Completed = 'completed',
  Preparing = 'preparing'
}

export enum EmployerPaymentStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Complete = 'COMPLETE'
}

export type Invoice = {
  id: number
  countryCode: CountryCode
  state: InvoiceState
  dateCreated: Date
  dueDate: Date
  numberOfUsersReimbursed: number // How many users received benefits on this invoice.
  currencyCode: CurrencyCode
  userReimbursements: InvoiceUserReimbursement[]
  rosterSize: number
  invoiceBenefitPrograms: InvoiceBenefitProgram[]
  // Payment info
  isInAutoPayment: boolean
  employerPaymentStatus: EmployerPaymentStatus
  payments: Payment[]

  // Amount info
  processingFee: number // How much JOON charges per user/invite/whichever payment model they are on.
  exchangeRate: number // exchange rate of usd to country currency
  calculatedProcessingFee: number // invoice country process fee * exchange rate
  numberOfUsersChargedFor: number // How many users we charged for on this invoice.
  joonFees: number // The total amount JOON gets for this invoice.
  benefitTransfersTotal: number // How much was transferred to recipients for this invoice.
  benefitTransferFees: number // Fee for reimbursements; fee applied to international invoices
  creditCardSurcharge?: number // Credit card fees if they paid by card.
  alternativeReimbursement: boolean // If the employer is reimbursing outside of JOON.

  // processingFee + joonFees + benefitTransfersTotal + calculatedCreditCardSurcharge = totalAmount
  totalAmount: number // The amount the customer pays for this invoice.

  employerBusinessName: string
  employerId: number

  notes?: string
  stripeInvoiceId?: string
  stripeMetadata?: {
    serviceMonth: string
    rosterSize: number
    pricingPlan: string
    companyId: number
    reimbursementFee: string
  }

  processingPaymentInfo: null | PendingPaymentInfo

  // For JOON admin usage only
  createdAt: Date
  updatedAt: Date
}

export type LatestInvoice = Pick<Invoice, 'id' | 'dateCreated' | 'state' | 'dueDate'>

export type InvoiceBenefitProgram = {
  expenditureAllowance: number
  reimbursedAmount: number
  name: string
}

export type InvoiceUserReimbursement = {
  id: number
  amount: number
  transferUrl?: string
  status: string // TODO enum
  depositAccountPresent: boolean
  employeeName: string
  amountReceived?: number
}

export type InvoiceTableRow = {
  date: Date
  payDate: Date
  state: InvoiceState
  paymentMethod: string
  joonFees: number
  benefitTransferAmount: number
  benefitTransferFees: number
  id: number
  invoice: Invoice
  countryCode: CountryCode
  type: InvoiceType
}

export enum InvoiceType {
  Payroll = 'Payroll',
  JOON = 'JOON'
}
