import { FC } from 'react'
import { TableRow } from '@mui/material'

import { StyledTableCell } from 'Components/Table/StyledTableCell'
import { CountryFlagIcon } from 'Components/Icons/CountryFlagIcon'
import { CurrencyText } from 'Components/Text/CurrencyText'
import { Chip } from 'Components/Chip/Chip'
import { InvoiceStatus } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceStatus'
import { PayButton } from 'Pages/Employer/BillingPage/PayInvoice/PayButton'
import { InvoiceActionsTableCell } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceActionsTableCell'

import { useCountries } from 'Hooks/useCountries'

import { PurpleText } from 'Utils/styles/text'
import { InvoiceState, InvoiceTableRow, InvoiceType } from 'Utils/types/invoice'
import { displayMonthAndYear } from 'Utils/helpers/dateUtils'
import { elfGreenFaded, elfGreen, electricViolet, electricVioletFaded } from 'Utils/styles/colors'
import { getCurrency } from 'Utils/types/currencies'

type InvoiceRowProps = {
  row: InvoiceTableRow
  setSelected: () => void
  toggleModal: () => void
  showType: boolean
  showReimbursementFee?: boolean
  isJoonAdmin?: boolean
}

export const InvoiceRow: FC<InvoiceRowProps> = ({
  row,
  setSelected,
  toggleModal,
  showType,
  showReimbursementFee = false,
  isJoonAdmin = false
}) => {
  const { showCountries } = useCountries(isJoonAdmin)

  const backgroundColor = row.type === InvoiceType.JOON ? elfGreenFaded : electricVioletFaded
  const fontColor = row.type === InvoiceType.JOON ? elfGreen : electricViolet
  const invoiceCurrency = row.invoice.currencyCode
  const benefitsCurrency = getCurrency(row.invoice.countryCode)

  return (
    <TableRow key={row.id}>
      <StyledTableCell>{displayMonthAndYear(row.date)}</StyledTableCell>
      {showCountries && (
        <StyledTableCell>
          <CountryFlagIcon text={row.countryCode} countryCode={row.countryCode} />
        </StyledTableCell>
      )}
      <StyledTableCell>
        <InvoiceStatus invoiceState={row.state} payDate={row.payDate} />
      </StyledTableCell>
      <StyledTableCell>
        {row.state === InvoiceState.Open ? (
          row.invoice.isInAutoPayment ? (
            'Scheduled'
          ) : (
            <PayButton invoice={row.invoice} />
          )
        ) : (
          row.paymentMethod
        )}
      </StyledTableCell>
      <StyledTableCell>
        <PurpleText>
          <CurrencyText value={row.joonFees} currencyCode={invoiceCurrency} />
        </PurpleText>
      </StyledTableCell>
      {showReimbursementFee && (
        <StyledTableCell>
          <PurpleText>
            <CurrencyText value={row.benefitTransferFees} currencyCode={row.invoice.currencyCode} />
          </PurpleText>
        </StyledTableCell>
      )}
      <StyledTableCell>
        <PurpleText>
          <CurrencyText value={row.benefitTransferAmount} currencyCode={benefitsCurrency} />
        </PurpleText>
      </StyledTableCell>
      {showType && (
        <StyledTableCell>
          <Chip text={row.type} backgroundColor={backgroundColor} fontColor={fontColor} />
        </StyledTableCell>
      )}
      <InvoiceActionsTableCell
        setSelected={setSelected}
        toggleModal={toggleModal}
        invoice={row.invoice}
      />
    </TableRow>
  )
}
