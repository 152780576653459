export type DepositAccount = {
  id: number
  accountId: string
  institutionName: string
  mask: string
  manuallyVerified: boolean
}

export enum PurchaseAccountProvider {
  Plaid = 'plaid',
  TrueLayer = 'true_layer'
}

export type PurchaseAccount = {
  id: number
  accountId: string
  provider: string
  institutionName: string
  accountType: string
  mask: string
  name: string
  manuallyVerified: boolean
  plaidRelinkToken?: string

  // Truelayer purchase account
  purchaseAccountConnectionId: number
  renewBy: Date
}
