import { InvoiceTableRow, Invoice, InvoiceType, EmployerPaymentStatus } from 'Utils/types/invoice'
import { paymentInfoWithStars } from 'Utils/helpers/paymentTypes'

export const convertInvoiceToTableRow = (invoice: Invoice): InvoiceTableRow => {
  const isPaymentProcessing = invoice.employerPaymentStatus === EmployerPaymentStatus.Sent

  return {
    id: invoice.id,
    date: invoice.dateCreated,
    countryCode: invoice.countryCode,
    state: invoice.state,
    paymentMethod: paymentInfoWithStars(
      isPaymentProcessing ? invoice.processingPaymentInfo : invoice.payments?.[0]?.paymentInfo
    ),
    joonFees: invoice.joonFees,
    benefitTransferAmount: invoice.benefitTransfersTotal,
    benefitTransferFees: invoice.benefitTransferFees,
    payDate: invoice.payments?.[0]?.createdAt,
    type: invoice.alternativeReimbursement ? InvoiceType.Payroll : InvoiceType.JOON,
    invoice
  }
}

export const findLatestInvoiceDate = (invoices: Invoice[]): Date | null => {
  if (invoices.length === 0) return null

  const dates = invoices
    .map((invoice) => invoice.dateCreated)
    .sort((a, b) => {
      return b.getTime() - a.getTime()
    })

  return dates[0]
}
