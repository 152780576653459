import { useEffect, useState } from 'react'

export enum ScriptEvent {
  Load = 'load',
  Error = 'error'
}
export enum ScriptStatus {
  Idle = 'idle',
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error'
}
const createScript = (src: string) => {
  const script: HTMLScriptElement = document.createElement('script')
  script.src = src
  script.async = true
  script.setAttribute('data-status', ScriptStatus.Loading)

  document.body.appendChild(script)

  // Store status so that we are able to access it in other instances of hook
  const setAttributeFromEvent = (event: Event) => {
    if (script) {
      script.setAttribute(
        'data-status',
        event.type === ScriptEvent.Load ? ScriptStatus.Ready : ScriptStatus.Error
      )
    }
  }
  script.addEventListener(ScriptEvent.Load, setAttributeFromEvent)
  script.addEventListener(ScriptEvent.Error, setAttributeFromEvent)

  return script
}

export const useScript = (src: string) => {
  const [status, setStatus] = useState<ScriptStatus | null>(
    src ? ScriptStatus.Loading : ScriptStatus.Idle
  )

  useEffect(() => {
    // In case we need to wait on other data needed for constructing the script URL passed.
    if (!src) {
      setStatus(ScriptStatus.Idle)
      return
    }

    // Fetch existing script using the source.
    // We do this in case it is already being loaded by another component using this hook.
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`)
    if (!script) {
      script = createScript(src)
    } else {
      // Grab existing script status from attribute and set state
      const status = script.getAttribute('data-status')
      setStatus(status ? (status as ScriptStatus) : null)
    }

    // Event handler in order to update state
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === ScriptEvent.Load ? ScriptStatus.Ready : ScriptStatus.Error)
    }

    script.addEventListener(ScriptEvent.Load, setStateFromEvent)
    script.addEventListener(ScriptEvent.Error, setStateFromEvent)

    // Clean up
    return () => {
      if (script) {
        script.removeEventListener(ScriptEvent.Load, setStateFromEvent)
        script.removeEventListener(ScriptEvent.Error, setStateFromEvent)
      }
    }
  }, [src])

  return status
}
