import { StyledButton } from 'Components/Buttons/StyledButton'
import { PayInvoiceModal } from 'Pages/Employer/BillingPage/PayInvoice/PayInvoiceModal'

import { useToggled } from 'Hooks/useToggled'

import { Invoice } from 'Utils/types/invoice'

export const PayButton = ({ invoice }: { invoice: Invoice }) => {
  const { toggled, handleToggle } = useToggled(false)

  return (
    <>
      <StyledButton onClick={handleToggle} primary size="small" id="pay.label" />
      <PayInvoiceModal open={toggled} onClose={handleToggle} invoice={invoice} />
    </>
  )
}
