import { ApiInvoice, ApiLatestInvoiceData, ApiPayment } from 'Services/apiResponseTypes'
import { Payment } from 'Utils/types/payment'
import { Invoice } from 'Utils/types/invoice'
import { isPaymentMethod } from 'Utils/types/paymentMethod'
import { parseServerDate } from 'Utils/helpers/dateUtils'

export const parseInvoiceApiResponseData = (apiInvoice: ApiInvoice): Invoice => ({
  ...apiInvoice,
  dateCreated: parseServerDate(apiInvoice.dateCreated),
  dueDate: parseServerDate(apiInvoice.dueDate),
  numberOfUsersReimbursed: apiInvoice.employeeCountWithAmount,
  isInAutoPayment: apiInvoice.autoPayment,
  numberOfUsersChargedFor: apiInvoice.chargeFeeCount,
  benefitTransfersTotal: apiInvoice.benefitTransferAmount,
  benefitTransferFees: apiInvoice.benefitTransferFees,
  totalAmount: apiInvoice.amount,
  invoiceBenefitPrograms: apiInvoice.invoiceBenefitPrograms,
  joonFees: apiInvoice.joonFees ?? 0,
  payments: parseApiPaymentList(apiInvoice.payments),
  userReimbursements: parseApiReimbursements(apiInvoice.benefitTransfers),
  rosterSize: apiInvoice.stripeMetadata?.rosterSize || 0,
  employerBusinessName: apiInvoice.employer.business_name ?? apiInvoice.employer.name ?? '',
  employerId: apiInvoice.employer.id,
  currencyCode: apiInvoice.currencyCode,
  exchangeRate: apiInvoice.exchangeRate,
  calculatedProcessingFee: apiInvoice.calculatedProcessingFee,
  createdAt: parseServerDate(apiInvoice.createdAt),
  updatedAt: parseServerDate(apiInvoice.updatedAt)
})

export const parseInvoice = (invoice?: ApiLatestInvoiceData) => {
  if (!invoice) return undefined

  return {
    id: invoice.id,
    dateCreated: parseServerDate(invoice.dateCreated),
    state: invoice.state,
    dueDate: parseServerDate(invoice.dueDate)
  }
}

const parseApiReimbursements = (reimbursements?: ApiInvoice['benefitTransfers']) => {
  if (!reimbursements) return []

  return reimbursements.map(
    ({ id, amount, transferUrl, status, user, depositAccount, amountReceived }) => ({
      id,
      amount,
      transferUrl,
      status,
      employeeName: user.title,
      depositAccountPresent: depositAccount?.id !== undefined,
      amountReceived
    })
  )
}

const parseApiPaymentList = (paymentList?: ApiPayment[]): Payment[] => {
  if (!paymentList) return []

  return paymentList.map(
    ({ id, amount, createdAt, autoPayment, paymentMethodJson: paymentInfo }) => {
      const parsedPaymentInfo = isPaymentMethod(paymentInfo)
        ? {
            ...paymentInfo
          }
        : paymentInfo
        ? {
            stripeChargeId: paymentInfo.stripeChargeId,
            stripeCustomerId: paymentInfo.stripeCustomerId
          }
        : null

      return {
        id,
        amount,
        autoPayment,
        createdAt: parseServerDate(createdAt),
        paymentInfo: parsedPaymentInfo
      }
    }
  )
}
