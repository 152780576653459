import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { CurrencyCode, CurrencySymbol } from 'Utils/types/currencies'
import {
  doubleDecimalRegex,
  intercedingNegativeSign,
  nonNumberRegex,
  singleDecimalRegex
} from 'Utils/helpers/currency'
import { Invoice } from 'Utils/types/invoice'
import { firstOfFollowingMonth, firstOfMonth, formatDateForServer } from 'Utils/helpers/dateUtils'
import { findLatestInvoiceDate } from 'Utils/helpers/invoice'
import { toast } from 'react-toastify'
import { useAddUserAllowanceMutation } from 'Hooks/api/JOONAdmin/useAddUserAllowanceMutation'
import { User } from 'Utils/types/user'
import { BenefitProgram } from 'Utils/types/benefitProgram'

export type AllowanceFormValues = {
  amount: string
  start: Date
  end?: Date
  reason: string
}

type UseAllowanceFormArgs = {
  currencyCode: CurrencyCode
  invoices: Invoice[]
  userId: User['id']
  benefitProgramId: BenefitProgram['id']
}

export const useAllowanceForm = ({
  currencyCode,
  invoices,
  userId,
  benefitProgramId
}: UseAllowanceFormArgs) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm<AllowanceFormValues>()

  const { isLoading, mutateAsync } = useAddUserAllowanceMutation()

  const earliestAllowanceStartDate = useMemo(() => {
    const latestInvoiceDate = findLatestInvoiceDate(invoices)

    return latestInvoiceDate ? firstOfFollowingMonth(latestInvoiceDate) : firstOfMonth(new Date())
  }, [invoices])

  const currencySymbol = useMemo(() => CurrencySymbol[currencyCode], [currencyCode])

  const registerOptions = {
    start: { required: 'A start date for the allowance is required.' },
    reason: { required: 'A reason for creating this allowance is required.' },
    amount: {
      pattern: {
        value: new RegExp(
          `^${currencySymbol.replace(
            /[-/\\^$*+?.()|[\]{}]/g,
            '\\$&'
          )}\\s*(-?[1-9][0-9]*(\\.[0-9]+)?|0*\\.0*1[0-9]*)$`
        ),
        message: 'A valid, non-zero amount is required.'
      },
      onChange: (e: { target: { value: string } }) => {
        const cleanedValue = e.target.value
          .replace(nonNumberRegex, '')
          // prevent any negative signs after the start of string
          .replace(intercedingNegativeSign, '$1')
          // Prevent multiple decimal points from being used
          .replace(singleDecimalRegex, '$1')
          // Prevent more than 2 digits after the decimal
          .replace(doubleDecimalRegex, '$1')

        e.target.value = `${currencySymbol} ${cleanedValue}`
        return e
      }
    }
  }

  const handleSubmission: SubmitHandler<AllowanceFormValues> = async ({
    amount,
    start,
    end,
    reason
  }) => {
    const response = await mutateAsync({
      userId,
      benefitProgramId,
      amount: amount.replace(currencySymbol, '').trim(),
      start: formatDateForServer(start),
      end: end ? formatDateForServer(end) : null,
      reason
    })

    // Reset form upon successful allowance modification
    if (response.success) {
      reset()
    }
  }

  const handleError: SubmitErrorHandler<AllowanceFormValues> = (errors) => {
    if (errors.amount?.message) toast.error(errors.amount.message)
    if (errors.start?.message) toast.error(errors.start.message)
    if (errors.reason?.message) toast.error(errors.reason.message)
  }

  return {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    errors,
    earliestAllowanceStartDate,
    registerOptions,
    currencySymbol,
    isLoading,
    handleError,
    handleSubmission
  }
}
